import { useRef } from 'react'
import lodash from 'lodash'
import { signal } from '@preact/signals-core'
import MonacoEditor from '@monaco-editor/react'
import terms from 'assets/terms'
import { Resizer } from 'components'
import { EvalPipelineSignal } from 'services'

import './EditorPanel.scss'

export const errorEditorSignal = signal<string>(null)

export default function EditorPanel() {
  const editorRef = useRef<HTMLDivElement>(null)
  const options = {
    readOnly: false,
    minimap: { enabled: false },
    selectOnLineNumbers: true,
    automaticLayout: true,
    wordWrap: true,
  }

  const updateValue = (val: string) => {
    EvalPipelineSignal.value.config = val
  }

  const debouncedSetValue = lodash.debounce(updateValue, 300)

  const handleChange = (val: string) => {
    debouncedSetValue(val)
  }

  return (
    <div className="editor">
      <h4>{terms.Pages.Pipeline.editor.title}</h4>
      <div className="flex-center">
        <div className={`wrapper${errorEditorSignal?.value ? ' errors-displayed' : ''}`} ref={editorRef}>
          <MonacoEditor
            options={options}
            value={EvalPipelineSignal.value.config}
            theme="vs-dark"
            language="python"
            onChange={handleChange}
          />
          <div className="errors">
            {errorEditorSignal?.value && <p>{errorEditorSignal.value}</p>}
          </div>
        </div>
        <Resizer wrapperRef={editorRef} />
      </div>
    </div>
  )
}
