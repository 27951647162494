/* eslint-disable max-len */
export const terms = {
  Common: {
    loading: 'Chargement',
    updated: 'Modifié',
    cancel: 'Annuler',
    create: 'Créer',
    send: 'Envoyer',
    save: 'Sauvegarder',
    execute: 'Exécuter',
    search: 'Rechercher',
    copyLink: 'Copier le lien',
    linkCopied: 'Lien copié dans le presse-papier',
    invit: 'Inviter',
    input: 'Entrée',
    true: 'Oui',
    false: 'Non',
  },
  Error: {
    forbidden: {
      appTitle: 'Vous n\'avez pas les droits nécessaires pour accéder à cette application.',
      appSubtitle: 'Veuillez contacter votre administrateur.',
      content: 'Vous n\'avez pas les droits pour accéder à cette ressource. Veuillez réessayer ou contacter votre administrateur.',
      exitButton: 'Retour au portail des applications',
    },
    httpStatus: {
      forbidden: 'Vous n\'avez pas les droits pour effectuer cette action.',
      notFound: 'La ressource demandée n\'existe pas.',
      internalServerError: 'Erreur de traitement de la requête côté serveur.',
      badGateway: 'Le serveur est indisponible.',
      unknownError: 'Une erreur inconnue est survenue.',
      badRequest: 'Vérifiez les informations que vous avez entrées et réessayez.',
    },
  },
  Pages: {
    Pipelines: {
      title: 'Projets',
    },
    Pipeline: {
      editor: {
        title: 'Python',
      },
      header: {
        creative: 'Mode visuel',
        code: 'Mode code',
        run: 'Sauvegarder',
        back: 'Projets',
      },
      stream: {
        title: 'Console',
      },
      parameters: {
        missingDescription: (nodeName: string) => `Description de “${nodeName}“ manquante.`,
      },
    },
  },
  Modals: {
    Create: {
      new: 'Nouveau projet',
      delete: (name: string) => `Supprimer le projet ${name} ?`,
      name: 'Nom',
      group: 'Groupe',
    },
  },
  Messages: {
    success: {
      pipelineUpdated: 'Projet mis à jour',
      copiedToClipboard: 'Url copiée dans le presse-papier',
    },
    errors: {
      missingPipelineParams: 'Veuillez renseigner tous les paramètres des noeuds avant de sauvegarder la pipeline.',
    },
  },
} as const

export default terms
