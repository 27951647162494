/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { ReactElement, useEffect, useRef, useState } from 'react'
import helpIcon from 'assets/icons/help.svg'
import { NodeModal, modalSignal } from 'components'
import { handleRecursiveFill } from 'utils'

import './InputNode.scss'

type Props = {
  icon: ReactElement
  label: string
  description: string
  type: string
  color: string
}

type NodeType = 'customNode'

export default function InputNode({ icon, label, description, type, color }: Props) {
  const inputRef = useRef<HTMLDivElement>(null)
  const [clickCaptured, setClickCaptured] = useState(false)
  const onDragStart = (event: React.DragEvent<HTMLDivElement>, nodeType: NodeType) => {
    event.dataTransfer.setData('application/reactflow', nodeType)
    event.dataTransfer.effectAllowed = 'move'
    event.dataTransfer.setData('type', type)
  }

  const onDragEnd = () => {
    setClickCaptured(false)
  }

  const onMouseDown = () => {
    setClickCaptured(true)
  }

  const onMouseUp = () => {
    setClickCaptured(false)
  }

  const handleDisplayDescription = () => {
    modalSignal.value = <NodeModal title={label} description={description} />
  }

  useEffect(() => {
    if (inputRef?.current) {
      const svg = inputRef.current?.querySelector('svg')
      handleRecursiveFill(svg, color)
    }
  }, [inputRef])

  return (
    <div
      className={`input-node flex-center ${type} ${clickCaptured ? 'click-captured' : ''}`}
      style={{ color }}
      onDragStart={event => onDragStart(event, 'customNode')}
      onDragEnd={onDragEnd}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      draggable
      ref={inputRef}
    >
      {icon}
      <span>{label}</span>
      <img onClick={handleDisplayDescription} src={helpIcon} alt="help" />
    </div>
  )
}
