import { ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as DeleteIcon } from 'assets/icons/close.svg'
import { Pipeline } from 'types'
import { Button, ButtonStyle, DeleteModal, modalSignal } from 'components'

import './PipelineCard.scss'

type Props = {
  pipeline: Pipeline
  pending?: boolean
}

function PipelineCard({ pipeline, pending }: Props): ReactElement {
  const navigate = useNavigate()

  const handleNavigation = () => {
    navigate(`/pipelines/${pipeline.id}`)
  }

  const handleDelete = () => {
    modalSignal.value = <DeleteModal pipeline={pipeline} />
  }

  return (
    <div className={`card-project ${pending ? 'pending' : ''}`}>
      <h2>{pipeline.name}</h2>
      <p>{pipeline.group}</p>
      <Button text="Voir" onClick={handleNavigation} />
      <Button text="" style={ButtonStyle.borderLess} icon={<DeleteIcon />} onClick={handleDelete} />
    </div>
  )
}

PipelineCard.defaultProps = {
  pending: false,
}

export default PipelineCard
