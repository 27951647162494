import { Roles } from 'types'
import { useAppSelector } from 'utils'

export const useRoles = () => {
  const { appPermissions } = useAppSelector(state => state.user)
  const isDev = appPermissions.includes(Roles.DEV)
  const hasAccess = appPermissions.includes(Roles.ACCESS)

  return {
    rolesLoaded: appPermissions.length > 0,
    isDev,
    hasAccess,
    hasAccessOnly: hasAccess && !isDev,
  }
}
