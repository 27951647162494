/* eslint-disable camelcase */
import React, { useState, useRef, useCallback, useEffect } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  useNodesState,
  useEdgesState,
  Controls,
  Connection,
  MarkerType,
  Node,
  Edge,
  EdgeTypes,
} from 'reactflow'
import { v4 as uuidv4 } from 'uuid'
import {
  IsConnectingNodeSignal,
  PipelinesSignal,
  getEdgeItem,
  getNodeItem,
  getNodeType,
  getPipeline,
  transformPipelineEdgesToEdges,
  transformPipelineNodesToNodes,
} from 'services'
import { PipelineEdge, PipelineNode } from 'types'
import CustomNode from './node/InputNode'
import EditableEdge from './edge/EditableEdge'

import 'reactflow/dist/style.css'
import './CanvasView.scss'

const nodeTypes = {
  customNode: CustomNode,
}

const edgeTypes: EdgeTypes = {
  customEdge: EditableEdge,
}

export default function CanvasView() {
  const [searchParams, setSearchParams] = useSearchParams()
  const urlParams = useParams<{id: string}>()
  const pipeline = getPipeline(urlParams.id)
  const reactFlowWrapper = useRef(null)
  const [nodes, setNodes, onNodesChange] = useNodesState<PipelineNode>(
    transformPipelineNodesToNodes(pipeline?.nodes) || [],
  )
  const [edges, setEdges, onEdgesChange] = useEdgesState<PipelineEdge>(
    transformPipelineEdgesToEdges(pipeline?.edges) || [],
  )
  const [reactFlowInstance, setReactFlowInstance] = useState(null)
  const [newNodeId, setNewNodeId] = useState(null)
  const computedEdges = edges.map(edge => ({ ...edge,
    markerEnd: {
      type: MarkerType.ArrowClosed,
      width: 15,
      height: 15,
      color: '#3A4457',
    },
    style: {
      strokeWidth: 1,
      stroke: '#3A4457',
    } }))

  const onNodeClick = (_: React.MouseEvent, node: Node<PipelineNode>) => {
    setSearchParams({ nodeId: node.data.node_id })
  }

  const onEdgeClick = (_: React.MouseEvent, edge: Edge<PipelineEdge>) => {
    setSearchParams({ edgeId: edge.id })
  }

  const onNodesDelete = () => {
    setSearchParams({})
  }

  const onConnect = useCallback(
    (params: Connection) => {
      setEdges(eds => {
        const newEdges = addEdge({ ...params, type: 'customEdge' }, eds)
        setSearchParams({ edgeId: newEdges[newEdges.length - 1].id })
        return newEdges
      })
    },
    [],
  )

  const onDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.dataTransfer.dropEffect = 'move'
  }, [])

  const onDrop = useCallback(
    async (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault()

      setNodes(no => no.map(node => ({ ...node, selected: false })))
      const reactflowType = event.dataTransfer.getData('application/reactflow')
      const nodeType = getNodeType(event.dataTransfer.getData('type'))

      if (typeof reactflowType === 'undefined' || !reactflowType) {
        return
      }

      const position = reactFlowInstance.screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      })

      const id = uuidv4()

      const newNode = {
        id,
        type: reactflowType,
        position,
        selected: true,
        data: {
          node_id: id,
          node_type_slug: nodeType.slug,
          position,
          parameters: nodeType.parameters.map(param => ({
            parameter_name: param.name,
            parameter_value: param.default,
          })),
        },
      }

      setNodes(nds => nds.concat(newNode))
      // Not sure if this is the best way to do it
      // setNodes is async and we need the new node id after it's set
      setNewNodeId(id)
      setSearchParams({ nodeId: id })
    },
    [reactFlowInstance],
  )

  const onPaneClick = () => {
    if (!IsConnectingNodeSignal.value) {
      setSearchParams({})
    }
  }

  useEffect(() => {
    if (newNodeId) {
      setSearchParams({ nodeId: newNodeId })
    }
  }, [newNodeId])

  useEffect(() => {
    if (!searchParams.get('nodeId')) {
      setNodes(nodes.map(n => ({ ...n, selected: false })))
    }
  }, [searchParams.get('nodeId')])

  useEffect(() => {
    if (!pipeline?.nodes) return
    pipeline.nodes = nodes.map(({ position, data }) => {
      const params = getNodeItem(urlParams.id, data.node_id)?.parameters || []
      const paramsWithDefault = data.parameters.map(param => {
        const parameter = params.find(p => p.parameter_name === param.parameter_name)
        return {
          parameter_name: param.parameter_name,
          parameter_value: parameter?.parameter_value || param.parameter_value,
        }
      })
      const roundedPosition = {
        x: Math.round(position.x),
        y: Math.round(position.y),
      }
      return { ...data, position: roundedPosition, parameters: paramsWithDefault }
    })
  }, [nodes])

  useEffect(() => {
    if (!pipeline?.edges) return
    pipeline.edges = edges.map(({ id, source, target }) => ({
      edge_id: id,
      source_node_id: source,
      target_node_id: target,
      target_input: getEdgeItem(urlParams?.id, id)?.target_input || '',
    }))
  }, [edges])

  useEffect(() => {
    if (pipeline?.nodes) setNodes(transformPipelineNodesToNodes(pipeline.nodes))
    if (pipeline?.edges) setEdges(transformPipelineEdgesToEdges(pipeline.edges))
  }, [PipelinesSignal.value])

  return (
    <div className="canvas">
      <ReactFlowProvider>
        <div className="reactflow-wrapper" ref={reactFlowWrapper}>
          <ReactFlow
            fitViewOptions={{ maxZoom: 1 }}
            nodes={nodes}
            edges={computedEdges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            onInit={setReactFlowInstance}
            onDrop={onDrop}
            onDragOver={onDragOver}
            onNodeClick={onNodeClick}
            onEdgeClick={onEdgeClick}
            onPaneClick={onPaneClick}
            onNodesDelete={onNodesDelete}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            defaultEdgeOptions={{ type: 'customEdge' }}
            fitView
          >
            <Controls />
          </ReactFlow>
        </div>
      </ReactFlowProvider>
    </div>
  )
}
