import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ActivePanel } from 'types'
import { topBarTitleSignal } from 'components'
import { getPipeline } from 'services'
import { getPipelineRequest } from 'requests'
import PanelHeader from './header/PanelHeader'
import EditorPanel from './editor/EditorPanel'
import CreativePanel from './creative/CreativePanel'
import StreamView from './stream/StreamView'
import CanvasView from './canvas/CanvasView'
import ParametersView from './parameters/ParametersView'

import './PipelinePage.scss'

type Params = {
  id: string
}

export default function PipelinePage() {
  const params = useParams<Params>()
  const pipeline = getPipeline(params.id)
  const [panelDisplayed, setPanelDisplayed] = useState<ActivePanel>(ActivePanel.CREATIVE)

  useEffect(() => {
    if (pipeline) topBarTitleSignal.value = `${pipeline.group} - ${pipeline.name}`
    else getPipelineRequest(params.id)
  }, [pipeline])

  const handleChangePanel = (panel: ActivePanel) => {
    setPanelDisplayed(panel)
  }

  return (
    <div id="pipeline">
      <PanelHeader active={panelDisplayed} onChangePanel={handleChangePanel} />
      {panelDisplayed === ActivePanel.CODE && <EditorPanel />}
      {panelDisplayed === ActivePanel.CREATIVE && <CreativePanel />}
      <CanvasView />
      <StreamView />
      <ParametersView />
    </div>
  )
}
