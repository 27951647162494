/* eslint-disable prefer-const */
/* eslint-disable camelcase */
import { useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { fetchEventSource } from '@microsoft/fetch-event-source'
import MAIN_API from 'config/config'
import terms from 'assets/terms'
import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg'
import {
  Button, ButtonStyle, AreaInput, Loader, toastSignal, ToastLevel,
} from 'components'
import { getPipeline } from 'services'
import { errorEditorSignal } from '../editor/EditorPanel'

import './StreamView.scss'

type StreamMessage = {
  id: string
  message: string
}

export default function StreamView() {
  const urlParams = useParams<{ id: string }>()
  const pipeline = getPipeline(urlParams.id)
  const { group, name } = pipeline || {}
  const runUrl = `${MAIN_API.api}/llmp/v1/pipelines/${encodeURIComponent(group)}/${encodeURIComponent(name)}/run/`
  const messagesRef = useRef<HTMLDivElement>(null)
  const [streamMessages, setStreamMessages] = useState<StreamMessage[]>([])
  const [streamPending, setStreamPending] = useState<boolean>(false)
  const [abortController, setAbortController] = useState(new AbortController())
  const [inputValue, setInputValue] = useState<string>('')

  const handleStreamInput = async () => {
    if (!group || !name) {
      errorEditorSignal.value = 'Pipeline group or name not found, try to re-run configuration'
      return
    }

    const id = uuidv4()
    setStreamPending(true)

    const formatUpdateMessage = (message: string | undefined, update: string) => {
      if (!message) return update
      const updatedMessage = `${message}${update}`
      if (encodeURIComponent(update) === '') {
        return `${updatedMessage}\n`
      }
      return updatedMessage.split('\\n').join('\n')
    }

    try {
      await fetchEventSource(
        runUrl,
        {
          method: 'POST',
          body: JSON.stringify({ input_text: inputValue }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
          signal: abortController.signal,
          async onopen(response) {
            if (response.status >= 400) {
              throw new Error(`Server responded with status : ${response?.status}`)
            }
          },
          onmessage: event => {
            setStreamMessages(prev => {
              const newMessages = [...prev]
              const index = newMessages?.findIndex(({ id: messageId }) => messageId === id)

              if (index === -1) {
                return [...newMessages, { id, message: event.data }]
              }

              newMessages[index] = {
                ...newMessages[index],
                message: formatUpdateMessage(newMessages?.at(index)?.message, event.data),
              }

              return newMessages
            })
            messagesRef.current.scrollTop = messagesRef.current.scrollHeight
          },
          onerror(e) {
            throw new Error(e)
          },
        },
      )
    } catch (e) {
      toastSignal.value = { message: e?.message || 'An error occured', severity: ToastLevel.ERROR }
    }

    setStreamPending(false)
  }

  const handleInputChange = (value: string) => {
    setInputValue(value)
  }

  const handleCancelStream = () => {
    abortController.abort()
    setAbortController(new AbortController())
  }

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(runUrl)
    toastSignal.value = { message: terms.Messages.success.copiedToClipboard, severity: ToastLevel.SUCCESS }
  }

  return (
    <div className="stream views">
      <h4>
        <span>{terms.Pages.Pipeline.stream.title}</span>
        {streamPending && <Loader variant="small" />}
      </h4>
      <div className="flex-center api-url" onClick={handleCopyUrl}>
        <CopyIcon />
        <a href="#">{runUrl}</a>
      </div>
      <div className="actions flex-column-center">
        <AreaInput label={terms.Common.input} defaultValue="" onChange={handleInputChange} />
        {streamPending ? (
          <Button text={terms.Common.cancel} style={ButtonStyle.borderLess} onClick={handleCancelStream} />
        ) : (
          <Button text={terms.Common.execute} onClick={handleStreamInput} />
        )}
      </div>
      <span className="message-label">Sortie</span>
      <div className="messages" ref={messagesRef}>
        {streamMessages.map(({ id, message }) => <p key={id}>{message}</p>)}
      </div>
    </div>
  )
}
