import { useEffect, useState } from 'react'

import '../wrapper.scss'
import './CheckInput.scss'

type Props = {
  defaultValue?: boolean
  label: string
  shouldReset?: boolean
  onChange: (value: boolean) => void
}

export default function Check({ defaultValue, label, shouldReset, onChange }: Props) {
  const [value, setValue] = useState(defaultValue)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.checked)
    onChange(event.target.checked)
  }

  useEffect(() => {
    if (shouldReset) {
      setValue(false)
      onChange(false)
    }
  }, [shouldReset])

  return (
    <div className="check input-wrapper">
      <label className="control control-checkbox" htmlFor={label}>
        {label}
        <input
          id={label}
          type="checkbox"
          checked={value}
          onChange={handleChange}
        />
        <div className="control_indicator" />
      </label>
    </div>
  )
}

Check.defaultProps = {
  shouldReset: false,
  defaultValue: false,
}
