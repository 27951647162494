/* eslint-disable camelcase */
import { signal } from '@preact/signals-core'
import { ReactComponent as EnterIcon } from 'assets/icons/enter.svg'
import { ReactComponent as ExitIcon } from 'assets/icons/arrow-rigth.svg'
import { ReactComponent as SaveIcon } from 'assets/icons/save.svg'
import { ReactComponent as BrainIcon } from 'assets/icons/brain.svg'
import { ReactComponent as ShareIcon } from 'assets/icons/share.svg'
import { ReactComponent as PDFIcon } from 'assets/icons/pdf.svg'
import { ReactComponent as CutIcon } from 'assets/icons/cut.svg'
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg'
import { ReactComponent as TemplateIcon } from 'assets/icons/template.svg'
import { ReactComponent as DefaultIcon } from 'assets/icons/config.svg'
import {
  EvalResponse, NodesGroup, NodeType, Pipeline, PipelineEdge, PipelineNode,
} from 'types'
import { Edge, Node } from 'reactflow'

type State = {
  config: string
  response: EvalResponse
}

const initialState: State = {
  config: localStorage.getItem('saved_pipeline_configuration') || '',
  response: { loading: false },
}

export const PipelinesSignal = signal<{results: Pipeline[], loading: boolean}>({ results: [], loading: false })
export const EvalPipelineSignal = signal<State>(initialState)
export const NodeTypeSignal = signal<NodeType[]>([])
export const IsConnectingNodeSignal = signal<boolean>(false)

const colors = ['#3d93d1', '#D1583D', '#7E3DD1', '#3DD1B8', '#D1353D']
const nodeTypeToIcon = (type: string) => {
  switch (type) {
    case 'pipeline_input':
      return EnterIcon
    case 'send_message':
      return ExitIcon
    case 'save_document':
    case 'save_embedding':
      return SaveIcon
    case 'vllm_completions':
      return BrainIcon
    case 'generate_embedding':
      return ShareIcon
    case 'pdf_to_text':
      return PDFIcon
    case 'recursive_text_splitter':
      return CutIcon
    case 'web_scraping':
    case 'retrieve_document':
      return SearchIcon
    case 'template':
      return TemplateIcon
    default:
      return DefaultIcon
  }
}

/**
 * Organize nodes by category
 *
 * Provides a color and an icon for each category
 *
 * @returns - The nodes grouped by category
 */
export const categorizedNodes = (): NodesGroup[] => {
  let categoriesLength = 0

  const categories = NodeTypeSignal.value.reduce((acc, node) => {
    const category = acc.find(g => g.label === node.group)

    if (category) {
      category.nodes.push({ ...node, icon: nodeTypeToIcon(node.slug) })
    } else {
      acc.push({
        label: node.group,
        color: colors[categoriesLength],
        nodes: [{ ...node, icon: nodeTypeToIcon(node.slug) }],
      })
      categoriesLength += 1
    }

    return acc
  }, [] as NodesGroup[])

  return categories
}

/**
 * Find a pipeline by its id
 *
 * @param id - The id of the pipeline
 * @returns The pipeline if found
 */
export const getPipeline = (id: string) => PipelinesSignal.value.results.find(p => p.id === id)

/**
 * Find a node type definition by its type or label
 *
 * @param search - The type or label to search for
 * @returns The node type if found
 */
export const getNodeType = (search: string) => categorizedNodes().flatMap(c => c.nodes).find(
  n => n.slug === search || n.name === search,
)

/**
 * Find a category by the type of one of its nodes
 *
 * @param type - The type of the node
 * @returns The category if found
 */
export const getNodeCategory = (type: string) => categorizedNodes().find(c => c.nodes.some(n => n.slug === type))

/**
 * Find a node item by its id
 *
 * @param pipelineId - The id of the pipeline
 * @param nodeId - The id of the node
 * @returns - The node if found
 */
export const getNodeItem = (pipelineId: string, nodeId: string) => {
  const pipeline = getPipeline(pipelineId)
  return pipeline?.nodes.find(({ node_id }) => node_id === nodeId)
}

/**
 * Find an edge item by its id
 *
 * @param pipelineId - The id of the pipeline
 * @param edgeId - The id of the edge
 * @returns - The edge if found
 */
export const getEdgeItem = (pipelineId: string, edgeId: string) => {
  const pipeline = getPipeline(pipelineId)
  return pipeline?.edges.find(({ edge_id }) => edge_id === edgeId)
}

/**
 * Transform custom PipelineNodes to reactFlow Nodes
 *
 * @param nodes - PipelineNodes to transform
 * @returns - The transformed Nodes
 */
export const transformPipelineNodesToNodes = (nodes: PipelineNode[]): Node[] => nodes?.map(node => ({
  id: node.node_id,
  type: 'customNode',
  position: node.position,
  data: node,
}))

/**
 * Transform custom PipelineEdges to reactFlow Edges
 *
 * @param edges - PipelineEdges to transform
 * @returns - The transformed Edges
 */
export const transformPipelineEdgesToEdges = (edges: PipelineEdge[]): Edge[] => edges?.map(edge => ({
  id: edge.edge_id,
  source: edge.source_node_id,
  target: edge.target_node_id,
  type: 'customEdge',
  animated: true,
}))

/**
 * Add or update a node parameter value in a pipeline
 *
 * @param pipelineId - The id of the pipeline
 * @param nodeId - The id of the node
 * @param name - The name of the parameter
 * @param value - The new value of the parameter
 */
export const updateNodeItemParameters = (
  pipelineId: string,
  nodeId: string,
  name: string,
  value: string | number | boolean,
) => {
  const pipeline = getPipeline(pipelineId)
  const nodeItem = getNodeItem(pipelineId, nodeId)
  const nodeType = getNodeType(nodeItem?.node_type_slug)
  const defaultValue = nodeType.parameters.find(p => p.name === name)?.default

  if (!nodeItem) {
    return
  }

  const newParameters = [
    ...nodeItem.parameters?.filter(param => param.parameter_name !== name) || [],
    {
      parameter_name: name,
      parameter_value: value || defaultValue,
    },
  ]

  pipeline.nodes = pipeline.nodes.map(node => {
    if (node.node_id === nodeId) {
      return {
        ...node,
        parameters: newParameters,
      }
    }

    return node
  })
}

/**
 * Update the target input of an edge in a pipeline
 *
 * @param pipelineId - The id of the pipeline
 * @param edgeId - The id of the edge
 * @param value - The new value of the target input
 */
export const updateEdgeInput = (pipelineId: string, edgeId: string, value: string) => {
  const pipeline = getPipeline(pipelineId)
  const edge = pipeline.edges.find(e => e.edge_id === edgeId)

  if (!edge) {
    return
  }

  edge.target_input = value
}

/**
 * Check if all pipeline parameters have a value
 *
 * @param pipeline - The pipeline to check
 * @returns - True if all parameters have a value
 */
export const checkPipelineParams = (pipeline: Pipeline) => {
  const { nodes = [] } = pipeline || {}

  return nodes.every(node => {
    const parameters = node.parameters || []
    return parameters.every(param => param.parameter_value !== undefined && param.parameter_value !== null)
  })
}
