type Props = {
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  label: string
  action: () => void
  active?: boolean
  hidden?: boolean
}

export default function Action({ Icon, label, action, active, hidden }: Props) {
  return (
    <div
      className={`flex-center header-action${active ? ' active' : ''}${hidden ? ' hidden' : ''}`}
      onClick={() => !hidden && action()}
    >
      <div className="wrapper">
        <Icon />
        <p>{label}</p>
      </div>
    </div>
  )
}

Action.defaultProps = {
  active: false,
  hidden: false,
}
