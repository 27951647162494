import { ReactElement, StrictMode, Suspense, useEffect, useState } from 'react'
import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom'
import '@preact/signals-react/auto'
import { auth } from '@osrdata/app_core'
import { useAppDispatch, useAppSelector } from 'utils'
import { terms } from 'assets/terms'
import { useRoles } from 'services'
import { getNodeTypesRequest } from 'requests'
import {
  Loader, TopBar, ToastMessage, ModalWrapper, modalSignal, toastSignal,
} from 'components'
import { DeniedPage, PipelinesPage, PipelinePage } from 'pages'
import 'moment-timezone'

import 'App.scss'

/** Externalize router logic in its own component
 * in order to use useLocation hook
 */
function Router() {
  const location = useLocation()

  useEffect(() => {
    // reset root signals when changing route
    modalSignal.value = undefined
    toastSignal.value = { ...toastSignal.value, timeout: 0 }
  }, [location.pathname])

  return (
    <Routes>
      <Route element={<PipelinesPage />} path="/" />
      <Route element={<PipelinesPage />} path="/pipelines" />
      <Route element={<PipelinePage />} path="/pipelines/:id" />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  )
}

export default function App(): ReactElement {
  const dispatch = useAppDispatch()
  const { hasAccess, rolesLoaded } = useRoles()
  const { isLogged, isLoading } = useAppSelector(state => state.user)
  const [accessDenied, setAccessDenied] = useState(false)

  useEffect(() => {
    dispatch(auth.attemptLoginOnLaunch())
  }, [])

  useEffect(() => {
    setAccessDenied(rolesLoaded && isLogged && !hasAccess)
  }, [isLogged, hasAccess, isLoading, rolesLoaded])

  const renderApp = () => {
    if (isLoading) return <Loader message={terms.Common.loading} standalone />
    if (accessDenied) return <DeniedPage />

    getNodeTypesRequest()

    return (
      <>
        <Router />
        <ModalWrapper />
      </>
    )
  }

  if (!isLoading && !isLogged) return null

  return (
    <StrictMode>
      <Suspense fallback={<Loader />}>
        <BrowserRouter>
          {!isLoading && <TopBar appName="LLMP" />}
          <div id="app">{renderApp()}</div>
          <ToastMessage />
        </BrowserRouter>
      </Suspense>
    </StrictMode>
  )
}
