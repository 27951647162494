/**
 * Recursively fill all SVG elements with a color
 *
 * @param svg - SVG element to fill
 * @param color - Color to fill the SVG elements with
 */
export const handleRecursiveFill = (svg: SVGSVGElement, color: string) => {
  svg.childNodes.forEach((child: SVGSVGElement) => {
    if (child.tagName === 'g') {
      handleRecursiveFill(child, color)
    } else {
      child.style.fill = color
    }
  })
}
