import { useEffect, useState } from 'react'

import '../wrapper.scss'
import './TextInput.scss'

type Props = {
  defaultValue?: string | number
  label?: string
  type?: 'string' | 'number' | 'float'
  hasError?: boolean
  placeholder?: string
  shouldReset?: boolean
  onChange: (value: string | number) => void
}

export default function Text({ defaultValue, label, type, hasError, placeholder, shouldReset, onChange }: Props) {
  const [value, setValue] = useState(defaultValue)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = event.target.value

    switch (type) {
      case 'number':
        inputValue = event.target.value.replace(/\D/g, '')
        onChange(parseInt(inputValue, 10))
        break
      case 'float':
        inputValue = event.target.value.replace(/[^0-9.-]/g, '')
        onChange(parseFloat(inputValue))
        break
      default:
        onChange(inputValue)
        break
    }

    setValue(inputValue)
  }

  useEffect(() => {
    if (shouldReset) {
      setValue('')
      onChange('')
    }
  }, [shouldReset])

  return (
    <div className="text input-wrapper">
      {label && <label htmlFor={label}>{label}</label>}
      <input
        className={`input${hasError ? ' error' : ''}`}
        type="text"
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
      />
    </div>
  )
}

Text.defaultProps = {
  label: undefined,
  type: 'string',
  shouldReset: false,
  placeholder: '',
  defaultValue: '',
  hasError: false,
}
