import { Button, modalSignal } from 'components'

import './NodeModal.scss'

type Props = {
  title: string
  description: string
}

export default function NodeModal({ title, description }: Props) {
  const handleHideModal = () => {
    modalSignal.value = undefined
  }

  return (
    <div className="node-modal">
      <h3>{title}</h3>
      <p>{description}</p>
      <Button text="Ok" onClick={handleHideModal} />
    </div>
  )
}
