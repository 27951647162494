import { useEffect, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Handle, NodeProps, Position, useReactFlow, useStore } from 'reactflow'
import { ReactComponent as Remove } from 'assets/icons/remove.svg'
import { handleRecursiveFill } from 'utils'
import { PipelineNode } from 'types'
import { IsConnectingNodeSignal, getNodeCategory, getNodeType } from 'services'

import './InputNode.scss'

const connectionNodeIdSelector = (state: { connectionNodeId: string }) => state.connectionNodeId

export default function InputNode({ data }: NodeProps<PipelineNode>) {
  const [, setSearchParams] = useSearchParams()
  const connectionNodeId = useStore(connectionNodeIdSelector)
  IsConnectingNodeSignal.value = !!connectionNodeId
  const { deleteElements } = useReactFlow()
  const type = data.node_type_slug
  const inputRef = useRef<HTMLDivElement>(null)
  const nodeType = getNodeType(type)
  const isStartingNode = nodeType?.inputs?.length === 0 && !nodeType?.any_input
  const isEndingNode = !nodeType?.output_type
  const { color } = getNodeCategory(type) || {}

  useEffect(() => {
    if (inputRef?.current) {
      const svg = inputRef.current?.querySelector('svg')
      handleRecursiveFill(svg, color)
    }
  }, [inputRef])

  const handleRemove = () => {
    deleteElements({ nodes: [{ id: data.node_id }] })
    setSearchParams({})
  }

  return (
    <div
      className={`input-node-dropped flex-center ${type}`}
      ref={inputRef}
      style={{ color }}
    >
      {!!nodeType?.icon && <nodeType.icon />}
      <span>{nodeType?.name}</span>
      {!isStartingNode && (
        <Handle
          type="target"
          position={Position.Top}
          className="w-16 !bg-teal-500"
        />
      )}
      {!isEndingNode && (
        <Handle
          type="source"
          position={Position.Bottom}
          className="w-16 !bg-teal-500"
        />
      )}
      <Remove className="remove" onClick={handleRemove} />
    </div>
  )
}
