import { ToastLevel, toastSignal } from 'components'

/**
 * Catch errors from an async function and display a toast error message
 *
 * @param fn - The function to catch errors from
 * @returns The result of the function or an error object
 */
export const catchable = async<T> (fn: () => Promise<T>, errorMessage?: string) => {
  try {
    return await fn()
  } catch (e) {
    toastSignal.value = {
      message: errorMessage || e?.message?.toString() || 'An error occured',
      severity: ToastLevel.ERROR,
    }
    return { error: e }
  }
}
