/* eslint-disable camelcase */
import { useEffect, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { EdgeProps, getBezierPath, EdgeLabelRenderer, BaseEdge } from 'reactflow'
import { getEdgeItem, getNodeItem, getNodeType, updateEdgeInput } from 'services'

import './EditableEdge.scss'

export default function EditableEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  target,
}: EdgeProps) {
  const params = useParams<{ id: string }>()
  const [searchParams] = useSearchParams()
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  })
  const edgeParamsId = searchParams.get('edgeId')
  const nodeTarget = getNodeItem(params.id, target)
  const nodeType = getNodeType(nodeTarget?.node_type_slug)
  const { inputs = [], any_input } = nodeType || {}
  const edge = getEdgeItem(params.id, id)
  const { target_input } = edge || {}
  const [selectedInput, setSelectedInput] = useState(target_input || inputs.at(0)?.name)
  const isEditable = any_input && inputs?.length === 0

  const handleInputChange = (input: string) => () => {
    setSelectedInput(input)
    updateEdgeInput(params.id, id, input)
  }

  const handleTransform = (x = -50, y = -50) => (
    { transform: `translate(${x}%, ${y}%) translate(${labelX}px,${labelY}px)` }
  )

  useEffect(() => {
    handleInputChange(target_input || inputs.at(0)?.name)()
  }, [])

  return (
    <>
      <BaseEdge id={id} path={edgePath} />
      <EdgeLabelRenderer>
        <div className="nodrag nopan edge-result" style={handleTransform()}>
          <input
            className={isEditable ? 'editable' : ''}
            type="text"
            value={selectedInput || ''}
            readOnly={!isEditable}
            placeholder="À modifier !"
            onChange={event => handleInputChange(event.target.value)()}
          />
        </div>
        {id === edgeParamsId && inputs.length > 1 && (
          <div className="nodrag nopan edge-modal" style={handleTransform(100)}>
            {inputs.map(({ name }) => (
              <div onClick={handleInputChange(name)} key={name}>{name}</div>
            ))}
          </div>
        )}
      </EdgeLabelRenderer>
    </>
  )
}
