/* eslint-disable camelcase */
import { useParams, useSearchParams } from 'react-router-dom'
import terms from 'assets/terms'
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import { getNodeItem, getNodeType, updateNodeItemParameters } from 'services'
import { NodeParamsType, ParamsType } from 'types'
import { TextInput, AreaInput, CheckInput } from 'components'

import './ParametersView.scss'

export default function ParametersView() {
  const [searchParams, setSearchParams] = useSearchParams()
  const urlParams = useParams<{id: string}>()
  const nodeId = searchParams.get('nodeId')
  const nodeItem = getNodeItem(urlParams.id, nodeId)
  const nodeType = getNodeType(nodeItem?.node_type_slug)

  const handleChange = (name: string) => (value: string | number | boolean) => {
    updateNodeItemParameters(urlParams.id, nodeId, name, value)
  }

  const renderInput = (nodeParamsType: NodeParamsType) => {
    const { name, type, default: defaultValue } = nodeParamsType
    const key = `${name}-${nodeId}`
    const params = nodeItem?.parameters?.find(param => param.parameter_name === name)

    switch (type) {
      case ParamsType.INT: return (
        <TextInput
          key={key}
          label={name}
          onChange={handleChange(name)}
          type="number"
          defaultValue={(params?.parameter_value || defaultValue) as number}
        />
      )
      case ParamsType.FLOAT: return (
        <TextInput
          key={key}
          label={name}
          onChange={handleChange(name)}
          type="float"
          defaultValue={(params?.parameter_value || defaultValue) as number}
        />
      )
      case ParamsType.BOOL: return (
        <CheckInput
          key={key}
          label={name}
          onChange={handleChange(name)}
          defaultValue={!!params?.parameter_value || !!defaultValue}
        />
      )
      default: return (
        <AreaInput
          key={key}
          label={name}
          resizable
          onChange={handleChange(name)}
          defaultValue={(params?.parameter_value || defaultValue || '') as string}
        />
      )
    }
  }

  if (!nodeType) {
    return null
  }

  return (
    <div className="parameters views">
      <CloseIcon onClick={() => setSearchParams({})} />
      <h4>{`“${nodeType?.name}”`}</h4>
      <p>{nodeType?.description || terms.Pages.Pipeline.parameters.missingDescription(nodeType?.name)}</p>
      {nodeType?.parameters?.map(type => renderInput(type))}
    </div>
  )
}
