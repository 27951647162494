import { useEffect, useState } from 'react'

import '../wrapper.scss'
import './AreaInput.scss'

type Props = {
  defaultValue: string | number
  resizable?: boolean
  label?: string
  placeholder?: string
  shouldReset?: boolean
  onChange: (value: string) => void
}

export default function Area({ defaultValue, resizable, label, placeholder, shouldReset, onChange }: Props) {
  const [value, setValue] = useState(defaultValue)

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.target.value)
    onChange(event.target.value)
  }

  useEffect(() => {
    if (shouldReset) {
      setValue('')
      onChange('')
    }
  }, [shouldReset])

  return (
    <div className={`area input-wrapper${resizable ? ' resizable' : ''}`}>
      {label && <label htmlFor={label}>{label}</label>}
      <textarea
        className="input"
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
      />
    </div>
  )
}

Area.defaultProps = {
  label: undefined,
  resizable: false,
  shouldReset: false,
  placeholder: '',
}
