import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, ButtonStyle, TextInput } from 'components'
import { postPipelineRequest } from 'requests'
import terms from 'assets/terms'
import { modalSignal } from '../ModalWrapper'

import './CreateModal.scss'

export default function CreateModal() {
  const navigate = useNavigate()
  const [data, setData] = useState({ name: '', group: '' })
  const [hasError, setHasError] = useState(false)
  const disabled = !data.name || !data.group

  const handleChange = (key: string) => (value: string) => {
    setHasError(false)
    setData(prev => ({ ...prev, [key]: value }))
  }

  const handleHideModal = () => {
    modalSignal.value = undefined
  }

  const handleCreate = async () => {
    const response = await postPipelineRequest({ ...data, nodes: [], edges: [] })
    if (!response?.error) {
      handleHideModal()
      navigate(`/pipelines/${response.id}`)
    } else {
      setHasError(true)
    }
  }

  return (
    <div className="create-modal flex-column-center">
      <h3>{terms.Modals.Create.new}</h3>
      <TextInput
        placeholder={terms.Modals.Create.name}
        onChange={handleChange('name')}
        hasError={hasError}
      />
      <TextInput
        placeholder={terms.Modals.Create.group}
        onChange={handleChange('group')}
        hasError={hasError}
      />
      <div className="flex-center">
        <Button style={ButtonStyle.borderLess} onClick={handleHideModal} text={terms.Common.cancel} />
        <Button onClick={handleCreate} text={terms.Common.save} disabled={disabled} />
      </div>
    </div>
  )
}
