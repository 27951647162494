/* eslint-disable react/no-array-index-key */
import { useEffect, useState } from 'react'
import { Pipeline } from 'types'
import { normalize } from 'utils'
import { PipelinesSignal } from 'services'
import { getPipelinesRequest } from 'requests'
import terms from 'assets/terms'
import { Button, CreateModal, modalSignal, topBarTitleSignal } from 'components'
import Card from './card/PipelineCard'
import SearchInput from './search/SearchInput'

import './PipelinesPage.scss'

export default function PipelinesPage() {
  const [search, setSearch] = useState('')
  const { loading, results: list } = PipelinesSignal.value
  const filteredList = list.filter(pipeline => {
    if (!search) return true
    const normalizeSearch = normalize(search)
    const normalizeGroup = normalize(pipeline.group)
    const normalizeName = normalize(pipeline.name)
    return normalizeGroup.includes(normalizeSearch) || normalizeName.includes(normalizeSearch)
  })

  // Generate at least 6 empty skeleton card minus the number of project
  const getLoadingSkeleton = () => {
    if (!loading) return null

    return Array.from({ length: 8 - (list?.length || 0) }).map(
      (_, index) => <Card key={`loading-${index}`} pipeline={{} as Pipeline} pending />,
    )
  }

  useEffect(() => {
    getPipelinesRequest()
    topBarTitleSignal.value = terms.Pages.Pipelines.title
  }, [])

  const handleCreatePipeline = () => {
    modalSignal.value = <CreateModal />
  }

  return (
    <div id="pipelines">
      <div className="actions">
        <SearchInput
          placeholder={terms.Common.search}
          onChange={(value: string) => setSearch(value)}
        />
        <Button text={terms.Common.create} onClick={handleCreatePipeline} />
      </div>
      <div className="wrapper">
        {filteredList.map(pipeline => (
          <Card key={pipeline?.id} pipeline={pipeline} />
        ))}
        {getLoadingSkeleton()}
      </div>
    </div>
  )
}
