/* eslint-disable max-len */
import { useNavigate, useParams } from 'react-router-dom'
import { ReactComponent as Code } from 'assets/icons/code.svg'
import { ReactComponent as Paint } from 'assets/icons/paint.svg'
import { ReactComponent as Play } from 'assets/icons/play.svg'
import { ReactComponent as Back } from 'assets/icons/previous.svg'
import terms from 'assets/terms'
import { Button, ButtonStyle, Loader, ToastLevel, toastSignal } from 'components'
import { ActivePanel } from 'types'
import { EvalPipelineSignal, checkPipelineParams, getPipeline, useRoles } from 'services'
import { deployPipelineRequest, patchPipelineRequest } from 'requests'
import Action from './HeaderAction'

import './PanelHeader.scss'

type Props = {
  active: ActivePanel
  onChangePanel: (action: ActivePanel) => void
}

export default function PanelHeader({ active, onChangePanel }: Props) {
  const urlParams = useParams<{ id: string }>()
  const { isDev } = useRoles()
  const navigate = useNavigate()

  const handleDisplayPanel = (action: ActivePanel) => () => onChangePanel(action)
  const handleDisplayLoader = () => EvalPipelineSignal.value.response.loading
  const handleNavigation = () => navigate('/pipelines/')
  const handleDeploy = async () => {
    const pipeline = getPipeline(urlParams.id)

    if (!checkPipelineParams(pipeline)) {
      toastSignal.value = { message: terms.Messages.errors.missingPipelineParams, severity: ToastLevel.ERROR }
      return
    }

    const patchResponse = await patchPipelineRequest(urlParams.id, pipeline)
    if (!patchResponse?.error) {
      await deployPipelineRequest(pipeline)
    }
  }

  return (
    <div className="project-header">
      <Button
        text={terms.Pages.Pipeline.header.back}
        onClick={handleNavigation}
        style={ButtonStyle.borderLess}
        icon={<Back />}
      />
      {isDev && (
        <div className="actions">
          <Action
            Icon={Paint}
            label={terms.Pages.Pipeline.header.creative}
            action={handleDisplayPanel(ActivePanel.CREATIVE)}
            active={active === ActivePanel.CREATIVE}
          />
          <Action
            Icon={Code}
            label={terms.Pages.Pipeline.header.code}
            action={handleDisplayPanel(ActivePanel.CODE)}
            active={active === ActivePanel.CODE}
          />
        </div>
      )}
      <div className="runner">
        {handleDisplayLoader() ? (
          <Loader variant="small" />
        ) : (
          <Button
            text={terms.Pages.Pipeline.header.run}
            onClick={handleDeploy}
            style={ButtonStyle.run}
            icon={<Play />}
          />
        )}
      </div>
    </div>
  )
}
