import { Pipeline } from 'types'
import { Button, ButtonStyle } from 'components'
import { deletePipelineRequest } from 'requests'
import terms from 'assets/terms'
import { modalSignal } from '../ModalWrapper'

type Props = {
  pipeline: Pipeline
}

export default function DeleteModal({ pipeline }: Props) {
  const handleHideModal = () => {
    modalSignal.value = undefined
  }

  const handleDelete = async () => {
    await deletePipelineRequest(pipeline.id)
    handleHideModal()
  }

  return (
    <div className="create-modal flex-column-center">
      <h3>{terms.Modals.Create.delete(`${pipeline.name} - ${pipeline.group}`)}</h3>
      <div className="flex-center">
        <Button style={ButtonStyle.borderLess} onClick={handleHideModal} text={terms.Common.false} />
        <Button onClick={handleDelete} text={terms.Common.true} />
      </div>
    </div>
  )
}
