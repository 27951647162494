import { signal } from '@preact/signals-react'
import { PORTAL_URL } from 'config/config'
import dgexLogo from 'assets/logos/dgex.svg'
import AccountMenu from './MenuAccount'

import './TopBar.scss'

type Props = {
  appName: string;
}

/**
 * Signal used to update the top bar title
 * It can be controlled from any component
 */
export const topBarTitleSignal = signal<string>('')

export default function TopBar({ appName }: Props) {
  return (
    <header>
      <div className="logos">
        <a className="flex-center" href={PORTAL_URL.url}>
          <img alt={appName} src={dgexLogo} />
        </a>
        <h1>{appName}</h1>
      </div>
      {topBarTitleSignal.value && <h3>{topBarTitleSignal.value}</h3>}
      <AccountMenu />
    </header>
  )
}
