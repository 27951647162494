/* eslint-disable camelcase */
import { deleteRequest, get, patch, post } from '@osrdata/app_core/dist/requests'
import { errorEditorSignal } from 'pages/pipeline/editor/EditorPanel'
import { catchable } from 'utils'
import { EvalResponse, NodeType, Pipeline } from 'types'
import { ToastLevel, toastSignal } from 'components'
import { EvalPipelineSignal, NodeTypeSignal, PipelinesSignal } from 'services'
import terms from 'assets/terms'

export const handleRunPipeline = async () => {
  const { config } = EvalPipelineSignal.value
  EvalPipelineSignal.value = { config, response: { loading: true } }
  errorEditorSignal.value = null

  let response = null

  try {
    response = await post<EvalResponse>(
      '/llmp/v1/pipelines/eval/',
      { pipeline_definition: config },
    )
    localStorage.setItem('saved_pipeline_configuration', config)
  } catch (e) {
    errorEditorSignal.value = e?.message || 'An error occured'
  }

  EvalPipelineSignal.value = { config, response: { ...response, loading: false } }
}

export const getPipelinesRequest = () => catchable(async () => {
  PipelinesSignal.value = { results: [], loading: true }
  const response = await get<{results: Pipeline[]}>('/llmp/pipelines/')
  PipelinesSignal.value = { results: response.results, loading: false }
})

export const getPipelineRequest = (id: string) => catchable(async () => {
  PipelinesSignal.value = { results: [], loading: true }
  const response = await get<Pipeline>(`/llmp/pipelines/${id}/`)
  PipelinesSignal.value = { results: [response], loading: false }
})

export const postPipelineRequest = (pipeline: Pipeline) => catchable(async () => {
  const response = await post('/llmp/pipelines/', pipeline)
  PipelinesSignal.value.results.push(response)
  return response
})

export const patchPipelineRequest = (id: string, pipeline: Partial<Pipeline>) => catchable(async () => {
  const response = await patch(`/llmp/pipelines/${id}/`, pipeline)
  PipelinesSignal.value = {
    ...PipelinesSignal.value,
    results: PipelinesSignal.value.results.map(p => (p.id === id ? { ...p, ...pipeline } : p)),
  }
  toastSignal.value = { message: terms.Messages.success.pipelineUpdated, severity: ToastLevel.SUCCESS }
  return response
})

export const deletePipelineRequest = (id: string) => catchable(async () => {
  await deleteRequest(`/llmp/pipelines/${id}`)
  PipelinesSignal.value = {
    ...PipelinesSignal.value,
    results: PipelinesSignal.value.results.filter(p => p.id !== id),
  }
})

export const getNodeTypesRequest = () => catchable(async () => {
  const response = await get<NodeType[]>('/llmp/v1/pipelines/nodes/')
  NodeTypeSignal.value = response
})

export const deployPipelineRequest = (pipeline: Pipeline) => catchable(async () => {
  await get(`/llmp/v1/pipelines/${pipeline.group}/${pipeline.name}/`)
})
