import { FunctionComponent, SVGProps } from 'react'

export enum ParamsType {
  STRING = 'str',
  ENUM = 'enum',
  INT = 'int',
  FLOAT = 'float',
  BOOL = 'bool',
}

export type NodeParamsType = {
  name: string
  type: ParamsType
  default?: string | number | boolean
  range?: {
    min: number
    max: number
  }
  values?: string[]
}

export type NodeType = {
  name: string
  slug: string
  group: string
  description: string
  any_input: boolean
  inputs: {
    name: string
    type: string
  }[]
  output_type: string
  parameters?: NodeParamsType[]
  icon?: FunctionComponent<SVGProps<SVGSVGElement>>
}

export type NodesGroup = {
  label: string
  color: string
  nodes: NodeType[]
}

export type PipelineNode = {
  node_id: string
  node_type_slug: string
  position: {
    x: number
    y: number
  }
  parameters?: {
    parameter_name: string
    parameter_value: string | number | boolean
  }[]
}

export type PipelineEdge = {
  edge_id: string
  source_node_id: string
  target_node_id: string
  target_input: string
}

export type Pipeline = {
  id?: string
  group: string
  name: string
  nodes: PipelineNode[]
  edges: PipelineEdge[]
}

export type EvalResponse = {
  pipeline_group?: string
  pipeline_name?: string
  svg_dot_graph?: string
  loading: boolean
}
