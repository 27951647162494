/* eslint-disable react/no-array-index-key */
import { categorizedNodes } from 'services'
import InputNode from './node/InputNode'

import './CreativePanel.scss'

export default function CreativePanel() {
  return (
    <div className="creative">
      <h4>Noeuds</h4>
      <div className="flex-center">
        <div className="wrapper">
          {categorizedNodes().map((category, i) => (
            <div key={`${category.label}-${i}`} className="category">
              <span className="label" style={{ color: category.color }}>{category.label}</span>
              {category.nodes.map(node => (
                <InputNode
                  key={node.name}
                  icon={<node.icon />}
                  label={node.name}
                  description={node.description}
                  type={node.slug}
                  color={category.color}
                />
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
